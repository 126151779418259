import React from 'react'
import { Body } from '../container'
import { Header, Footer } from '../component'
import '../component/style.css'

export default function Portfolio() {
  return (
    <>
        <Header />
        <Body />
        <Footer />
    </>
  )
}
